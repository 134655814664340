@layer module {
  .tab-pages {
    & .editor-container {
      background: var(--white);
      position: relative;
      flex: 1;
    }

    & .tiptap-menu-actions {
      display: flex;
      flex-wrap: nowrap;
      gap: var(--space-s);
      max-width: calc(100vw - var(--space-m)*3);
      white-space: nowrap;
      overflow-x: auto;
      padding: var(--space-s) 0;

      & .action {
        padding: var(--space-s);
        border-radius: var(--radius);
      }
      & .action:hover { background: var(--grey-lighter); }
      & .action:is(:focus, .is-active) { background: var(--grey-light); }
      & .toggle-bold { font-weight: bold; }
      & .toggle-italic { font-style: italic; }
      & .toggle-underline { text-decoration: underline; }
      & .toggle-strike { text-decoration: line-through; }
      & .toggle-code { font-family: var(--font-mono); }
    }
    
    & .tiptap-editor > :first-child {
      height: calc(100vh - 22rem);
      overflow: auto;
    }

    & .editor-floating-menu {
      position: absolute;
      bottom: var(--space-m);
      left: 0;
      width: 100%;
    }

    & .page-treeitem {
      padding: var(--space-m);
      display: block;
      user-select: none;
      color: var(--grey-darker);
      background: rgb(255 255 255 / 80%);

      &.is-opened { opacity: 0.6; }
      &:not(.is-opened) {
        & .open-button { cursor: pointer; }
        & .open-button:is(:hover, :focus) { filter: brightness(0.9); }
      }
    }
  }
}