@layer module {
  .reading-summary {
    background: rgb(255 255 255 / 50%);
    color: var(--grey-darker);

    &:hover, &:focus { filter: brightness(0.9); }
    &.is-read:not(:hover, :focus) { background: var(--grey-lighter); }

    &.is-read { color: var(--grey-dark); }
  }
}