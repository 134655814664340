@layer module {
  .palace-layout {
    --aside-width: 15rem;
    
    /* because parent has flex column and I need to take full screen. */
    flex: 1;
    position: relative;
    display: flex;
    align-items: stretch;

    & > .main {
      flex: 1;
      display: flex;
    }

    & .underlined-tabs {
      max-width: 100vw;
    }

    /* fix the layout */
    & > .aside {
      display: flex;
      position: fixed;
      z-index: 50;
      top: 3.5rem;
      left: 0;
      height: calc(100vh - 3.5rem);
      width: var(--aside-width);
      transform: translateX(-100%);
      background: rgb(255 255 255 / 95%);

      & > :first-child {
        height: 100%;
        overflow-y: auto;
      }

      & > .burger-trigger {
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 0 0.25rem 0 0;
        transform: translateX(100%);
      }

      & > *:not(.burger-trigger) { opacity: 0; }
    }

    & #palace-burger:checked + .aside {
      transform: translateX(0);
      box-shadow: var(--shadow);
      
      & > *:not(.burger-trigger) { opacity: 1; }
    }
  }

  @media (min-width: 768px) {
    .palace-layout {
      & > .aside {
        position: static;
        width: unset;
        flex-basis: var(--aside-width);
        flex-shrink: 0;
        background: unset;
        transform: unset;
        display: flex;
        height: unset;

        & > :not(.burger-trigger) { opacity: 1; }
        & > .burger-trigger { display: none; }
      }
    }
  }
}