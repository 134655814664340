@layer module {
  .explorer-nav {
    padding: var(--space-m) 0;

    & .room-treeitem {
      color: var(--grey);
      /* position: relative; */
      
      /* &::before {
        content: "";
        position: absolute;
        width: 2px;
        height: calc(100% - 2em);
        top: 2em;
        left: 1.2em;
        background: var(--grey-light);
        border-radius: 1rem;
      } */

      & summary {
        padding: var(--space-s) var(--space-m);
        cursor: not-allowed;
      }

      & summary::marker { content: ""; }
      &[open] { color: var(--black); }

      &:not(.is-opened) summary { cursor: pointer; }
    }

    & .binder-treeitem {
      color: var(--grey);
      padding: var(--space-s) var(--space-m);
      text-align: left;
      
      &:not(:disabled) { cursor: pointer;}
      &:disabled {
        color: var(--black);
        opacity: 1;
      }
    }
  }
}