@layer module {
  .scraped-page {
    line-height: 1.25;
    
    & :is(code, pre) {
      background: var(--grey-darker);
      color: var(--white);
      font-size: 0.8em;
      border-radius: 0.25rem;
    }

    & sup {
      vertical-align: super;
      font-size: smaller;
    }
    & sub {
      vertical-align: sub;
      font-size: smaller;
    }

    & [aria-hidden="true"] { display: none; }

    & video { max-width: 100%; }
    & * + :is(p, pre, div) { margin-top: var(--space-s); }
    & * + :is(h1, h2, h3, h4, h5, h6) {
      margin-top: var(--space-l);
      margin-bottom: var(--space-m);
    }
    
    & table {
      display: inline-table;
      max-width: 100%;
      width: 100%;
      overflow-x: auto;
      border-spacing: 0;
      border-collapse: collapse;
      border: 1px solid var(--grey);
    }
    & th { font-weight: var(--bold); }
    & :is(th, td) {
      padding: var(--space-s);
      border: 1px solid var(--grey);
    }
    & td:not(:first-child) {
      text-align: center;
    }

    & :is(input, textarea, select) {
      border: 1px solid var(--grey);
      border-radius: var(--radius);
      background: var(--white);
      color: var(--grey);
      padding: var(--space-s);
      margin: var(--space-s) 0;
    }

    & button {
      border: 1px solid currentColor;
      border-radius: var(--radius);
      background: var(--white);
      color: AccentColor;
      background: AccentTextColor;
      padding: var(--space-s) var(--space-m);
      margin: var(--space-s) 0;
      cursor: pointer;
    }
  }
}