@layer module {
  @media (max-width: 768px) {
    .app-header {
      & .logo-text { display: none; }
      & :is(a, .link) {
        font-size: 1.5em;
        line-height: 1.3rem;
  
        & > small { display: none; }
      }
    }
  }
  
  .app-header :is(a, .link) {
    padding: var(--space-m);
    text-decoration: none;
    background: rgb(255 255 255 / 0%);
    transition: background-color .5s ease-out;
    color: var(--grey-dark);
    position: relative;
    text-shadow: 0px 0px 2px var(--black);
    /* filter: brightness(1); */
    display: flex;
    align-items: center;
    justify-content: start;
    gap: calc(var(--space-s) * 2);

    & > small { text-shadow: none; }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: AccentColor;
      border-radius: 1rem;
      transform-origin: left;
      transform: scaleX(0);
      transition: transform 0.3s ease-in-out;
    }

    &:is(:hover, :focus, .is-active) {
      color: var(--black);
      
      &::before {
        transform: scaleX(1);
        transform-origin: right;
      }
    }
    
    &:hover, &:focus {
      background: rgb(255 255 255 / 50%);
      filter: brightness(1);
    }
  }
}