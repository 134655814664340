@layer module {
  .page-toolbar {
    display: flex;
    flex-direction: column;
    gap: var(--space-s);
    justify-content: center;
  }
  
  @media (min-width: 768px) {
    .page-toolbar {
      flex-direction: row;
    }
  }
}