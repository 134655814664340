.coffee-machine {
  --width: 300px;
  --height: calc(280 / 300 * var(--width));
  --black-plastic: #282323;
  --grey-plastic: #909899;
  --grey-metal-light: #e2e5e6;
  --grey-metal-dark: #c9cccc;
  --color-primary: var(--red-ish, #43beae);
  --color-primary-light: var(--pink, #9acfc5);
  --color-percolator: #282323;
  --color-cup: #fff;
  --color-coffee: #74372b;
  --color-smoke: whitesmoke;

  width: var(--width);
  height: var(--height);
  position: relative;
}

.coffee-machine-header {
  width: 100%;
  height: 28.5%; /* 80px */
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--grey-metal-light);
  border-radius: calc(10 / 300 * var(--width));
  
  
  & .button-1 { left: var(--offset-left); }
  & .button-2 { left: calc(var(--offset-left) * 1.5 + var(--size)); }
  
  & :is(.button-1, .button-2) {
    --size: calc(25 / 300 * var(--width)); /* 25px */
    --offset-left: calc(15 / 300 * var(--width));

    width: var(--size);
    height: var(--size);
    position: absolute;
    top: var(--size);
    background-color: var(--black-plastic);
    border-radius: 50%;

    &::after {
      --trail-size: calc(8 / 25 * var(--size)); /* 8px */
      content: "";
      width: var(--trail-size);
      height: var(--trail-size);
      position: absolute;
      bottom: calc(var(--trail-size) * -1);
      left: calc(50% - var(--trail-size) / 2);
      background-color: var(--grey-plastic);
    }
  }

  & .display {
    --size: calc(50 / 300 * var(--width)); /* 50px */
    width: var(--size);
    height: var(--size);
    position: absolute;
    top: calc(50% - var(--size) / 2);
    left: calc(50% - var(--size) / 2);
    border-radius: 50%;
    background-color: var(--color-primary-light);
    border: calc(var(--size) / 10) solid var(--color-primary);
    box-sizing: border-box;
  }
  & .details {
    width: calc(8 / 300 * var(--width)); /* 8px */
    height: calc(20 / 280 * var(--height)); /* 20px */
    position: absolute;
    top: calc(10 / 300 * var(--width));
    right: calc(10 / 300 * var(--width));
    background-color: var(--grey-plastic);
    box-shadow: calc(-12 / 300 * var(--width)) 0 0 var(--grey-plastic), calc(-24 / 300 * var(--width)) 0 0 var(--grey-plastic);
  }
}

.coffee-machine-recess {
  width: 90%;
  height: calc(160 / 280 * var(--height));
  position: absolute;
  top: calc(80 / 280 * var(--height));
  left: calc(50% - 45%);
  background-color: var(--grey-metal-dark);

  &::before {
    --radius: calc(10 / 300 * var(--width));
    content: "";
    width: 90%;
    height: calc(100 / 280 * var(--height));
    background-color: var(--grey-plastic);
    position: absolute;
    bottom: 0;
    left: calc(50% - 45%);
    border-radius: var(--radius) var(--radius) 0 0;
  }

  & .exit {
    --exit-width: calc(60 / 300 * var(--width));
    width: var(--exit-width);
    height: calc(20 / 280 * var(--height));
    position: absolute;
    top: 0;
    left: calc(50% - var(--exit-width) / 2);
    background-color: var(--black-plastic);

    &::before {
      --local-width: calc(50 / 300 * var(--width));
      --local-height: calc(20 / 280 * var(--height));
      content: "";
      width: var(--local-width);
      height: var(--local-height);
      border-radius: 0 0 50% 50%;
      position: absolute;
      bottom: calc(-1 * var(--local-height));
      left: calc(50% - var(--local-width) / 2);
      background-color: var(--color-percolator);
    }

    &::after {
      --size: calc(10 / 300 * var(--width));
      content: "";
      width: var(--size);
      height: var(--size);
      position: absolute;
      bottom: calc(-30 / 280 * var(--height));
      left: calc(50% - var(--size) / 2);
      background-color: var(--color-percolator);
    }
  }

  & .arm {
    width: calc(70 / 300 * var(--width));
    height: calc(20 / 280 * var(--height));
    position: absolute;
    top: calc(15 / 280 * var(--height));
    right: calc(25 / 300 * var(--width));
    background-color: var(--black-plastic);
    border-radius: calc(5 / 300 * var(--width));

    &::before {
      --local-width: calc(15 / 300 * var(--width));
      content: "";
      width: var(--local-width);
      height: calc(5 / 280 * var(--height));
      position: absolute;
      top: calc(7 / 280 * var(--height));
      left: calc(-1 * var(--local-width));
      /* background-color: #9e9495; */
      background-color: var(--grey-metal-light);
    }
  }

  & .cup {
    --local-width: calc(60 / 300 * var(--width));
    --radius-1: calc(70 / 300 * var(--width));
    --radius-2: calc(250 / 300 * var(--width));
    width: var(--local-width);
    height: calc(47 / 280 * var(--height));
    position: absolute;
    bottom: 0;
    left: calc(50% - var(--local-width) / 2);
    background-color: var(--color-cup);
    border-radius: 0 0 var(--radius-1) var(--radius-1) / 0 0 var(--radius-2) var(--radius-2);

    &::after {
      --size: calc(20 / 300 * var(--width));
      content: "";
      width: var(--size);
      height: var(--size);
      position: absolute;
      top: calc(6 / 280 * var(--height));
      right: calc(-13 / 300 * var(--width));
      border: calc(4 / 20 * var(--size)) solid var(--color-cup);
      border-radius: 50%;
    }
  }

  & .liquid {
    --local-width: calc(6 / 300 * var(--width));
    width: var(--local-width);
    height: calc(63 / 280 * var(--height));
    opacity: 0;
    position: absolute;
    top: calc(50 / 280 * var(--height));
    left: calc(50% - var(--local-width) / 2);
    background-color: var(--color-coffee);
    animation: liquid 2s .3s linear infinite;
  }

  & .smoke {
    --local-width: calc(8 / 300 * var(--width));
    width: var(--local-width);
    height: calc(20 / 280 * var(--height));
    position: absolute;  
    border-radius: calc(5 / 8 * var(--local-width));
    background-color: var(--color-smoke);
  }

  & .smoke-1 {
    opacity: 0;
    bottom: calc(50 / 280 * var(--height));
    left: calc(108 / 300 * var(--width));
    animation: smokeOne 3s .3s linear infinite;
  }

  & .smoke-2 {
    opacity: 0;
    bottom: calc(70 / 280 * var(--height));
    left: calc(120 / 300 * var(--width));
    animation: smokeTwo 3s .4s linear infinite;
  }

  & .smoke-3 {
    opacity: 0;
    bottom: calc(65 / 280 * var(--height));
    right: calc(120 / 300 * var(--width));
    animation: smokeTwo 3s .5s linear infinite;
  }

  & .smoke-4 {
    opacity: 0;
    bottom: calc(50 / 280 * var(--height));
    right: calc(108 / 300 * var(--width));
    animation: smokeOne 3s .6s linear infinite;
  }
}

@keyframes liquid {
  0% {
    height: 0px;  
    opacity: 1;
  }
  5% {
    height: 0px;  
    opacity: 1;
  }
  20% {
    height: calc(62 / 280 * var(--height));  
    opacity: 1;
  }
  95% {
    height: calc(62 / 280 * var(--height));
    opacity: 1;
  }
  100% {
    height: calc(62 / 280 * var(--height));
    opacity: 0;
  }
}

@keyframes smokeOne {
  0% {
    bottom: calc(20 / 280 * var(--height));
    opacity: 0;
  }
  40% {
    bottom: calc(50 / 280 * var(--height));
    opacity: .5;
  }
  80% {
    bottom: calc(80 / 280 * var(--height));
    opacity: .3;
  }
  100% {
    bottom: calc(80 / 280 * var(--height));
    opacity: 0;
  }
}
@keyframes smokeTwo {
  0% {
    bottom: calc(40 / 280 * var(--height));
    opacity: 0;
  }
  40% {
    bottom: calc(70 / 280 * var(--height));
    opacity: .5;
  }
  80% {
    bottom: calc(80 / 280 * var(--height));
    opacity: .3;
  }
  100% {
    bottom: calc(80 / 280 * var(--height));
    opacity: 0;
  }
}

.coffee-machine-footer {
  width: 95%;
  height: calc(15 / 280 * var(--height));
  position: absolute;
  bottom: calc(25 / 280 * var(--height));
  left: calc(50% - 47.5%);
  background-color: var(--color-primary);
  border-radius: calc(10 / 300 * var(--width));

  &::after {
    --radius: calc(10 / 300 * var(--width));
    
    content: "";
    width: 106%;
    height: calc(26 / 280 * var(--height));
    position: absolute;
    bottom: calc(-25 / 280 * var(--height));
    left: calc(-8 / 300 * var(--width));
    background-color: #000;
    border-radius: var(--radius) var(--radius) 0 0
  }
}